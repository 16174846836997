import { IDceZipFormat } from "@/@models/fichier";
import {
  ISociete,
  ISocietePrefs,
  ISocieteTag,
  ISocieteUnite,
} from "@/@models/societes";
import axios from "@/plugins/axios";

export const societesService = {
  /**
   * Recupere toutes les societes
   */
  getSocietes() {
    return axios.get<{
      societes: ISociete[];
    }>(`USER/SOCIETES/AO`);
  },
  /**
   * Recupere les preferences de la societe
   */
  getSocietePrefs(index) {
    return axios.get<{
      prefs: ISocietePrefs;
    }>(`SOCIETES/${index}/PREFS`);
  },
  /**
   * Recupere toutes les unites configurées dans BIMOffice
   */
  getUnites() {
    return axios.get<{
      unites: ISocieteUnite[];
    }>(`AUTRES/UNITES `);
  },
  /**
   * Recupere les tags par types ou tous
   * @param type
   */
  getTags(type: "" | "AO" = "") {
    return axios.get<{
      tags: ISocieteTag;
    }>(`AUTRES/TAGS/${type}`);
  },
  getTexteRiche(uidTexteRiche: string) {
    return axios.get<{
      result: string;
    }>(`AUTRES/TEXTE-RICHE/${uidTexteRiche}`);
  },

  getFile(token: string, predicate?: any) {
    return axios.get(`DOWNLOADS/FICHIERS/${token}`, {
      responseType: "blob",
      onDownloadProgress: predicate,
    });
  },

  postFiles(
    data: { nom: string; fichiers: string[]; formatZip?: IDceZipFormat },
    predicate?: any
  ): Promise<Blob> {
    return axios.post(`DOWNLOADS/FICHIERS`, data, {
      responseType: "blob",
      onDownloadProgress: predicate,
    });
  },
};
