import { UserAccess } from "@/stores";
import { RouteRecordRaw } from "vue-router";

export const projetsRoutesName = [
  "projets",
  "projet_info",
  "projet_intervenants",
  "projet_consultations",
  "projet_adjudication",
  "projet_box",
];

export const projetsRoutes: Array<RouteRecordRaw> = [
  {
    name: "projets",
    path: "projets",
    component: () => import("@/pages/ao/views/projets/ProjetsList.vue"),
    meta: {
      title: "Projets",
      access: [UserAccess.projets],
    },
  },
  {
    name: "projet",
    path: "projets/:uid_projet",
    redirect: {
      name: "projet_info",
    },
    component: () => import("@/pages/ao/views/projet/ProjetView.vue"),
    meta: {
      title: "projet",
      access: [UserAccess.projets],
    },
    children: [
      {
        path: "informations",
        name: "projet_info",
        component: () =>
          import(
            "@/pages/ao/views/projet/subviews/informations/ProjetInformationsView.vue"
          ),
        meta: {
          title: "Informations",
          access: [UserAccess.projets],
        },
      },
      {
        path: "intervenants",
        name: "projet_intervenants",
        component: () =>
          import(
            "@/pages/ao/views/projet/subviews/intervenants/ProjetIntervenantsView.vue"
          ),
        meta: {
          title: "Annuaire",
          access: [UserAccess.projets],
        },
      },
      {
        path: "adjudications",
        name: "projet_adjudication",
        component: () =>
          import(
            "@/pages/ao/views/projet/subviews/adjudication/ProjetAdjudicationView.vue"
          ),
        meta: {
          title: "Adjudication",
          access: [UserAccess.projets],
        },
      },
      {
        path: "box",
        name: "projet_box",
        component: () =>
          import("@/pages/ao/views/projet/subviews/box/ProjetBox.vue"),
        meta: {
          title: "box",
          access: [UserAccess.espaceEntreprise],
        },
      },
    ],
  },
];
