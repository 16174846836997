/**
 *
 * @param hex color string (#RRGGBB)
 * @returns [r, g, b] color array
 */
export const hex2rgb = (_hex: string) => {
  const hex = _hex.replace("#", "");
  const r = parseInt(hex.slice(0, 2), 16);
  const g = parseInt(hex.slice(2, 4), 16);
  const b = parseInt(hex.slice(4, 6), 16);
  return [r, g, b] as [number, number, number];
};

/**
 *
 * @param color [r, g, b]
 * @returns luminance of color
 */
export function calculateLuminance(color: [number, number, number]) {
  const [r, g, b] = color;
  return 0.299 * r + 0.587 * g + 0.114 * b;
}

/**
 *
 * @param color [r, g, b]
 * @returns [r, g, b] darkened if luminance is too high
 */
export function darkenIfTooLight(color: [number, number, number]) {
  const luminanceThreshold = 200; // Adjust this threshold as needed
  const darkeningFactor = 50; // Adjust this darkening factor as needed
  const luminance = calculateLuminance(color);
  if (luminance > luminanceThreshold) {
    const [r, g, b] = color;
    const darkenedColor = [
      Math.max(0, r - darkeningFactor),
      Math.max(0, g - darkeningFactor),
      Math.max(0, b - darkeningFactor),
    ];
    return darkenedColor;
  } else {
    return color;
  }
}

/**
 *
 * @param color [r, g, b]
 * @returns [r, g, b] lightened if luminance is too low
 */
export function lightenIfTooDark(color: [number, number, number]) {
  const luminanceThreshold = 50; // Adjust this threshold as needed
  const lighteningFactor = 50; // Adjust this lightening factor as needed
  const luminance = calculateLuminance(color);
  if (luminance < luminanceThreshold) {
    const [r, g, b] = color;
    const lightenedColor = [
      Math.min(255, r + lighteningFactor),
      Math.min(255, g + lighteningFactor),
      Math.min(255, b + lighteningFactor),
    ];
    return lightenedColor;
  } else {
    return color;
  }
}

export function colorIsLight(color: [number, number, number],minLuminance =200) {
  const luminance = calculateLuminance(color);
 return luminance > minLuminance;
}

export function getRandomColorHexa() {
  return "#000000".replace(/0/g, function () {
    return (~~(Math.random() * 16)).toString(16);
  });
}

/**
 * retourne une couleur en fonction d'une chaine de caractère
 */
export function stringToColour(str: string) {
  let hash = 0;
  str.split("").forEach((char) => {
    hash = char.charCodeAt(0) + ((hash << 5) - hash);
  });
  let colour = "#";
  for (let i = 0; i < 3; i++) {
    const value = (hash >> (i * 8)) & 0xff;
    colour += value.toString(16).padStart(2, "0");
  }
  return colour;
}

export function stringToColorRGB(str: string) {
  return hex2rgb(stringToColour(str));
}